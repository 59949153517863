const BlogData = [
    {
      id: 10,
      title: 'The Top Ten Best LEGO Bigfigs',
      slug: 'the-top-ten-best-lego-bigfigs',
      previewImg: 'best-lego-bigfigs.png',
      articleImg: 'best-lego-bigfigs2.png',
      alt: 'Best LEGO Bigfigs',
      summary: 'LEGO big figures, or bigfigs as they\'re more fondly known, are in essence scaled up minifigures. They come in at just under twice the height of the standard minifigure, as they\'re typically seven bricks tall as opposed to four bricks for minifigures.',
      date: '17th November 2024',
      updated: '17th November 2024',
      author: 'Graham',
      content: [
        <p class="mb-3">LEGO big figures, or bigfigs as they're more fondly known, are in essence scaled up minifigures. They come in at just under twice the height of the standard minifigure, as they're typically seven bricks tall as opposed to four bricks for minifigures. Bigfigs have been around since the end of the last millennium, when the Rock Monster was released under the Rock Raiders theme in 1999. This figure was almost non-poseable and it wasn't until 2008 that the first 'modern bigfig'arrived, which had the same level of articulation as minifigure, with the head, legs, and arms all being moveable.</p>,
        <p class="mb-3">Bigfigs are used to depict characters that are considerably larger than humans, and their use now spans multiple themes, including Star Wars, Marvel, DC, and Lord of the Rings to name but a few. Bigfigs are most commonly used for superheroes and supervillains, with the Hulk being the most frequently produced bigfig.</p>,
        <p class="mb-3">In this article, I take a look at the top ten best LEGO bigifgs based on the more than half a million user ratings in the Brickelo database. The ratings are constantly changing as more and more ratings get submitted, so this article is updated every month.</p>,
        <a href="https://brickelo.com/results/super-heroes/minifigures/sh576" class="text-green-400 font-bold"><h2 class="mb-3 font-bold text-xl text-green-400">10. Thanos (sh576)</h2></a>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh576.png" className="h-56"/>
          <p class="mb-3">LEGO have released numerous bigfigs of Thanos, the warlord who has featured heavily in the Marvel Cinematic Universe. This Thanos is from 2019, and while there are better and newer versions that have included more detail, this version has proved to be good enough to make the top ten.</p>
        </div>,
        <a href="https://brickelo.com/results/castle/minifigures/cas424" class="text-green-400 font-bold"><h2 class="mb-3 font-bold text-xl text-green-400">9. Troll (cas424)</h2></a>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/cas424.png" className="h-56 object-contain"/>
          <p class="mb-3">This Troll was released in LEGO Castle's wave of fantasy era sets in 2008 and it heralded the beginning of the modern bigfig. LEGO released several variants of this figure and it came in both dark tan and sand green. This bigfig definitely has a certain charm that proves popular amongst LEGO fans.</p>
        </div>,
        <a href="https://brickelo.com/results/super-heroes/minifigures/sh413" class="text-green-400 font-bold"><h2 class="mb-3 font-bold text-xl text-green-400">8. Hulk (sh413)</h2></a>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh413.png" className="h-56 min-w-72"/>
          <p class="mb-3">The first of two Hulks in the top ten came from the Thor: Ragnarok subtheme in 2017. While most Hulk bigfigs are very plain, this version includes a silver helmet, blue shoulder pad, and torso printing, which explains why he's considered one of the best.</p>
        </div>,
        <a href="https://brickelo.com/results/super-heroes/minifigures/sh932" class="text-green-400 font-bold"><h2 class="mb-3 font-bold text-xl text-green-400">7. Hulk (sh932)</h2></a>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh932.png" className="h-56 "/>
        <p class="mb-3">The second Hulk on this list is one of the most recent bigfigs of the character. It was included in the gigantic Avengers Tower (76269) from 2023 and portrays the Hulk in traditional olive green.</p>
        </div>,
        <a href="https://brickelo.com/results/super-heroes/minifigures/sh147" class="text-green-400 font-bold"><h2 class="mb-3 font-bold text-xl text-green-400">6. Gorilla Grodd (sh147)</h2></a>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh147.png" className="h-56 "/>
          <p class="mb-3">Gorilla Grodd is a lesser known comic book character but one that has appeared in several LEGO animated films and video games. This bigfig was the first and only time the character has appeared in a LEGO set and depicts him as seen in the Justice League.</p>
        </div>,
        <a href="https://brickelo.com/results/super-heroes/minifigures/sh896" class="text-green-400 font-bold"><h2 class="mb-3 font-bold text-xl text-green-400">5. Thanos (sh896)</h2></a>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh896.png" className="h-56 "/>
          <p class="mb-3">This is the newest Thanos bigfig and is one of the most detailed as it incorporates leg printing. The gold printing on the torso is also arguably the best representation of the supervillain's armour, so it deserves to be called the best Thanos bigfig.</p>
        </div>,
        <a href="https://brickelo.com/results/super-heroes/minifigures/sh414" class="text-green-400 font-bold"><h2 class="mb-3 font-bold text-xl text-green-400">4. Bane (sh414)</h2></a>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh414.png" className="h-56 "/>
          <p class="mb-3">Bane is often argued as being Batman's most dangerous adversary, and in comic book canon is the only one to get the better of the caped crusader. This is the only bigfig of Bane, as the character is more commonly depicted in minifigure form. This is one of the best bigfigs LEGO have released as it includes some original moulding that adds extra detail that's lacking in older bigfigs. One of its best features is the venom serum-filled tubes leading from his backpack into his arms.</p>
        </div>,
        <a href="https://brickelo.com/results/super-heroes/minifigures/sh321" class="text-green-400 font-bold"><h2 class="mb-3 font-bold text-xl text-green-400">3. Killer Croc (sh321)</h2></a>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh321.png" className="h-56 "/>
          <p class="mb-3">Killer Croc is a supervillain from the DC franchise who has been represented in both minifigure and bigfig form. This bigfig features claw parts that were first used for the <a href="https://brickelo.com/results/star-wars/minifigures/rancor" class="text-green-400 font-bold">Rancor</a> from LEGO Star Wars, and crocodile head parts first used in classic Castle sets. The overall effect is a very menacing bigfig who, in my opinion, is one of the coolest LEGO have ever made.</p>
        </div>,
        <a href="https://brickelo.com/results/overwatch/minifigures/ow017" class="text-green-400 font-bold"><h2 class="mb-3 font-bold text-xl text-green-400">2. Roadhog (ow017)</h2></a>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/ow017.png" className="h-56 "/>
          <p class="mb-3">LEGO introduced a short lived Overwatch theme that saw only two waves of sets released in 2019. However, it gave us two bigfigs, one of which was Roadhog, who is one of the heroes in the franchise. He's easily the most detailed and interesting bigfig LEGO have given us so far, as he features some fantastic moulds and printing. He's definitely worthy of being in the top three and he may well take the top spot soon. The other Overwatch bigfig, <a href="https://brickelo.com/results/overwatch/minifigures/ow011" class="text-green-400 font-bold">Winston</a>, sits just outside the top 10.</p>
        </div>,
        <a href="https://brickelo.com/results/the-lord-of-the-rings/minifigures/lor027" class="text-green-400 font-bold"><h2 class="mb-3 font-bold text-xl text-green-400">1. Cave Troll (lor027)</h2></a>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/lor027.png" className="h-56 "/>
          <p class="mb-3">LEGO has released two bigfigs from Middle Earth, one representing the <a href="https://brickelo.com/results/super-heroes" class="text-green-400 font-bold">Goblin King</a>, and this one representing the Cave Troll from the first Lord of the Rings movie, where the Fellowship battle their way through the Mines of Moria. Lord of the Rings is one of the most popular LEGO themes and many of its minifigures are found towards the top of the top 100. Therefore, it's not surprising the Cave Troll is the highest rated LEGO bigifig. However, popularity aside, this is a very nice looking minifigure thanks to its sand blue colour scheme and it does a decent job of depicting the cave troll from the movie.</p>
        </div>,
        <p class="mb-3">Don't agree with this list? If you want to vote on your favourite superhero minifigures and have your say, you can do so <a href="https://brickelo.com/results/super-heroes" class="text-green-400 font-bold">here</a>.</p>,
      ]
    },
    {
      id: 9,
      title: 'The Definitive List of the Top Ten Best LEGO Superhero Minifigures',
      slug: 'the-definitive-list-of-the-top-ten-best-lego-superhero-minifigures',
      previewImg: 'best-superhero-minifigures.png',
      articleImg: 'best-superhero-minifigures2.png',
      alt: 'Best LEGO Superhero Minifigures',
      summary: 'The first LEGO superhero minifigure was released in 2002 when we got a wave of Spider-Man set under the LEGO Studios theme. Now there are over 1,000 superhero minifigures depicting a large range of characters from the Marvel and DC universes. In this article, I go over the ten highest rated superhero minifigures based on over 500,000 user ratings.',
      date: '9th November 2024',
      updated: '5th January 2025',
      author: 'Graham',
      content: [
        <p class="mb-3">The first LEGO superhero minifigure was released in 2002 when we got a wave of Spider-Man set under the LEGO Studios theme. Now there are over 1,000 superhero minifigures depicting a large range of characters from the Marvel and DC universes.</p>,
        <p class="mb-3">In this article, I go over the ten highest rated superhero minifigures based on over 500,000 user ratings. The list of the best superhero minifigures is constantly changing and I update this list at the beginning of every month. If you want to check out the current list of the best 100 superhero minifigures, you can do so <a href="https://brickelo.com/ranking/super-heroes" class="text-green-400 font-bold">here</a>.</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/super-heroes/minifigures/sh116">10. Storm (sh116)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh116.png" className="h-56"/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f87171"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg><span class="text-red-400">2</span>
              </div>
            </div>
            <p class="mb-3">This minifigure was released in the X-Men vs. The Sentinel (76022) set from 2014. LEGO have produced three minifigures of Storm and this is the only one showing her in her black outfit. Each version features a unique cloak piece with arm holes that wraps around her body.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/super-heroes/minifigures/sh877">9. Adam Warlock (sh877)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh877.png" className="h-56 object-contain"/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">1</span>
              </div>
            </div>
            <p class="mb-3">Adam Warlock from the Guardians of the Galaxy, sits in 9th place, and is up one place from last month. This was one of my favourite minifigures from 2023 because of the excellent gold and red colour scheme and great detail throughout.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/super-heroes/minifigures/sh532">8. Batman (sh532)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh532.png" className="h-56 min-w-72"/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f87171"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg><span class="text-red-400">1</span>
              </div>
            </div>
            <p class="mb-3">This minifigure was a welcome break to the normal black attire of the caped crusader and that's because it depicts Knightmare Batman, who's an alternative version of the superhero. Dark tan is a great looking colour and in addition, this minifigure packs in a lot of detail.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/super-heroes/minifigures/sh455">7. Gentleman Ghost (sh455)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh455.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">2</span>
              </div>
            </div>
            <p class="mb-3">This is the only minifigure of the supervillain Gentleman Ghost from the DC franchise. The character is depicted in media with a transparent form with an invisible head, which LEGO have captured very well with the clear headpiece and all white attire.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/super-heroes/minifigures/colmar14">6. Moon Knight (colmar14)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/colmar14.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1 justify-center items-center">
                <div class="mx-1 h-1 w-4 bg-gray-500"></div>
                <span class="text-gray-400">0</span>
              </div>
            </div>
            <p class="mb-3">The second Marvel CMF series was the best licensed series LEGO have released and there were some clear favourites amongst the 12 to collect, two of which make the top ten. The first is the Moon Knight, who has printing on every surface and dual moulded legs. The all-white colour scheme also looks fantastic and personally I'm surprised this minifigure hasn't taken the top spot yet.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/super-heroes/minifigures/sh267">5. Ghost Rider (sh267)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh267.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f87171"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg><span class="text-red-400">3</span>
              </div>
            </div>
            <p class="mb-3">Two Ghost Rider minifigures are currently in the top ten. This was the first minifigure of this antihero, having been released in 2016, and is arguably the best (at least in my opinion). The torso print shows the character's fiery form underneath his black outfit, which looks really nice. What's unique about the Ghost Rider minifigures is the hair and head are formed from a single moulded piece, rather than being detachable like nearly every other minifigure.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/super-heroes/minifigures/sh327">4. Poison Ivy (sh327)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh327.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f87171"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg><span class="text-red-400">1</span>
              </div>
            </div>
            <p class="mb-3">There have been five minifigures of Poison Ivy and this is by far the most detailed. This minifigure makes use of some fantastic colours, including the vivid red hair piece. This is also the only Poison Ivy minifigure to include a fabric skirt and is the highest rated DC minifigure on this list. It's also the only female superhero minifigure to feature in the top ten.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/super-heroes/minifigures/colmar24">3. Wolverine (colmar24)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/colmar24.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">2</span>
              </div>
            </div>
            <p class="mb-3">The other minifigure from the second Marvel CMF series is Wolverine. This was the sixth different Wolverine LEGO released and is the most detailed, as it's the only one to include arm printing. All Wolverine minifigures, with the exception of the <a href="https://brickelo.com/results/super-heroes/minifigures/sh364" class="text-green-400 font-bold">Mighty Micros version</a>, are well rated, which reflects the popularity of this superhero.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/super-heroes/minifigures/sh520">2. Sheriff Deadpool (sh520)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh520.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">2</span>
              </div>
            </div>
          <p class="mb-3">The rarest and most expensive minifigure in the top ten is Sheriff Deadpool, who was a San Diego Comic-Con exclusive in 2018. Sheriff Deadpool is currently the <a href="https://brickelo.com/blog/every-lego-comic-con-minifigure-ranked-from-best-to-worst" class="text-green-400 font-bold">highest rated Comic-Con exclusive</a> and has some really cool and detailed printing. LEGO brought several fun and obscure characters to Comic-Con and this perhaps the finest example from that trend.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/super-heroes/minifigures/sh861">1. Ghost Rider (sh861)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh861.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1 justify-center items-center">
                <div class="mx-1 h-1 w-4 bg-gray-500"></div>
                <span class="text-gray-400">0</span>
              </div>
            </div>
          <p class="mb-3">The newest Ghost Rider minifigure from 2023 is currently the highest rated superhero minifigure by some margin. This result is fairly surprising given its not the most detailed minifigure. However, the face print and fiery hair mould make one of the coolest looking superhero minifigures.</p>
          </div>
        </div>,
        <p class="mb-3">Don't agree with this list? If you want to vote on your favourite superhero minifigures and have your say, you can do so <a href="https://brickelo.com/ranking/super-heroes" class="text-green-400 font-bold">here</a>.</p>,
      ]
    },
    {
      id: 8,
      title: 'The Definitive List of the Top Ten Best LEGO Collectible Minifigures',
      slug: 'the-definitive-list-of-the-top-ten-best-lego-collectible-minifigures',
      previewImg: 'best-collectible-minifigures.png',
      articleImg: 'best-collectible-minifigures2.png',
      alt: 'Best LEGO Collectible Minifigures',
      summary: 'LEGO Collectible Minifigures (CMFs) were first introduced in 2010 and there have since been 48 series, including 27 regular series and 21 licensed or themed series, with over 700 minifigures to collect. CMF series are typically where LEGO introduces some of its most creative and original minifigures that are amongst the very best you can buy. In this article, I run through the ten highest rated CMFs as of the beginning of November 2024.',
      date: '8th November 2024',
      updated: '5th January 2025',
      author: 'Graham',
      content: [
        <p class="mb-3">LEGO Collectible Minifigures (CMFs) were first introduced in 2010 and there have since been 48 series, including 27 regular series and 21 licensed or themed series, with over 700 minifigures to collect. CMF series are typically where LEGO introduces some of its most creative and original minifigures that are amongst the very best you can buy. In this article, I run through the ten highest rated CMFs as of the beginning of January 2024. This list is based on over 500,000 user ratings, and if you want to have your say on what the best CMFs are, you can vote for your favourites <a href="https://brickelo.com/ranking/collectable-minifigures" class="text-green-400 font-bold">here</a>.</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/collectable-minifigures/minifigures/col170">10. Yeti (col170)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/col170.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f87171"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg><span class="text-red-400">2</span>
              </div>
            </div>
            <p class="mb-3">The Yeti from Series 11 retains its place in the top ten this month but has fallen two places from December.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/collectable-minifigures/minifigures/col426">9. Vampire Knight (col426)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/col426.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">1</span>
              </div>
            </div>
            <p class="mb-3">The Vampire Knight is the newest CMF in the top ten, having been released in Series 25. This was a remake of the Bat Lord character who was the protagonist of the Fright Knights faction from the late 1990s. There's a lot of nostalgia around classic LEGO Castle and as a result this minifigure proved to be very popular. But nostalgia aside, it included some amazing printing, dual-moulded legs, and a nice cape to top it off.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/collectable-minifigures/minifigures/col234">8. Faun (col234)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/col234.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">1</span>
              </div>
            </div>
            <p class="mb-3">The Faun from Series 15 is one of two characters from Greek mythology to find itself in the top 10. Fantastical and historical characters have always been a hit amongst LEGO fans, so it's no surprise that they dominate the top tiers of LEGO CMFs. The Faun introduced two brand new moulds, the first being the goat legs that has since been used on various animal-themed minifigures, and the second being the horn/hair piece.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/collectable-minifigures/minifigures/col008">7. Demolition Dummy (col008)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/col008.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#f87171"
                  className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg><span class="text-red-400">1</span>
              </div>
            </div>
            <p class="mb-3">Having a demolition dummy as a minifigure was a genius move and a match made in heaven. There's a definite beauty in the simplicity of this minifigure and it's one of the most iconic CMFs. The Demolition Dummy appeared in the very first series and is the oldest in the top ten.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/collectable-minifigures/minifigures/col147">6. Roman Commander (col147)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/col147.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
          <div class="flex gap-1">
            <div class="font-bold">30 day Change:</div>
            <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f87171"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg><span class="text-red-400">4</span>
            </div>
            </div>
            <p class="mb-3">There have been several Roman-themed CMFs and the Roman Commander is clearly the best according to our users. There is a big demand in the LEGO community for more historical sets, and a Roman theme is very high on many AFOL wishlists, so this minifigure was much sought after. The minifigure itself has a great helmet and armour printing. This minifigure is up four places from last month.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/collectable-minifigures/minifigures/col088">5. Minotaur (col088)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/col088.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
          <div class="flex gap-1">
            <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">2</span>
              </div>
            </div>
            <p class="mb-3">The Minotaur from Series 6 is the second mythical minifigure in the top ten and moves up two places from last month. This minifigure has a great mould of the creature's bull's head and some nice printing, especially on the legs. The Minotaur is another very cool character to have as a minifigure.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/collectable-minifigures/minifigures/col204">4. Evil Wizard (col204)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/col204.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f87171"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg><span class="text-red-400">1</span>
              </div>
            </div>
            <p class="mb-3">The Evil Wizard is the first of two wizards in the top ten. It was released one series after the “good” Wizard (col179) to act as his antagonist. This minifigure has a great two piece cloak and some very cool skull printing down his front. Along with the Classic King, he was the minifigure everyone wanted from Series 13.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/collectable-minifigures/minifigures/col195">3. Classic King (col195)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/col195.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
            <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">2</span>
              </div>
            </div>
            <p class="mb-3">Another Castle inspired minifigure to make the top ten is the Classic King from Series 13. This minifigure has consistently been among the top five CMFs and currently sits in 5th place, down one from last month. There are some great colours on this minifigure and the ermine cloak is a very nice detail. It's definitely worthy of its place in the top ten.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/collectable-minifigures/minifigures/col179">2. Wizard (col179)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/col179.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">2</span>
              </div>
            </div>
            <p class="mb-3">The second wizard on this list has some charming moon and star printing across the torso, legs, and cloak. This minifigure was included in Series 12 and can be seen as an upgrade to, and perhaps a reimagining of, Majisto, who was the wizard in classic Castle sets.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/collectable-minifigures/minifigures/col114">1. Conquistador (col114)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/col114.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
            <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1 justify-center items-center">
                <div class="mx-1 h-1 w-4 bg-gray-500"></div>
                <span class="text-gray-400">0</span>
              </div>
            </div>
            <p class="mb-3">The Conquistador keeps its place at the top spot for the second month in a row. This is one of my personal favourite CMFs so it's nice to see it right up there. He has a fantastic gold breastplate and a great colour scheme, which make him one of the best looking CMFs. LEGO Pirates has always been popular and this minifigure makes a great addition to any Pirate-themed MOC.</p>
          </div>
        </div>,
        <p class="mb-3">If you've enjoyed this article, you can check out the top 100 best CMFs <a href="https://brickelo.com/results/collectable-minifigures" class="text-green-400 font-bold">here</a>.</p>,
      ]
    },
    {
      id: 7,
      title: 'The Definitive List of the Top Ten Best LEGO Star Wars Minifigures',
      slug: 'the-definitive-list-of-the-top-ten-best-lego-star-wars-minifigures',
      previewImg: 'best-star-wars-minifigures.png',
      articleImg: 'best-star-wars-minifigures2.png',
      alt: 'Best LEGO Star Wars Minifigures',
      summary: 'Since the LEGO Star Wars theme launched in 1999, nearly 1,500 Star Wars minifigures have been released, featuring around 500 different characters from the franchise. In this article, I provide a rundown of the top ten highest-rated Star Wars minifigures as of early January 2025, based on more than 500,000 ratings that have been submitted to date. This list reflects current rankings and will be updated monthly, so be sure to check back for the latest rankings!',
      date: '7th November 2024',
      updated: '5th January 2025',
      author: 'Graham',
      content: [
        <p class="mb-3">Since the LEGO Star Wars theme launched in 1999, nearly 1,500 Star Wars minifigures have been released, featuring around 500 different characters from the franchise.</p>,
        <p class="mb-3">In this article, I provide a rundown of the top ten highest-rated Star Wars minifigures as of early January 2025, based on more than 500,000 ratings that have been submitted to date. This list reflects current rankings and will be updated monthly, so be sure to check back for the latest rankings!</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/star-wars/minifigures/sw1096">10. Ahsoka Tano (sw1096)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw1096.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">1</span>
              </div>
            </div>
            <p class="mb-3">Ahsoka Tano is one of most popular characters from the Star Wars franchise and there have been some excellent minifigures of her to choose from. LEGO did a good job with the hair piece and there's some nice detail throughout, but this minifigure could have benefited from some arm printing. This version from 2020 might not be everyone's favourite Ahsoka as the one from 2016 <a href="https://brickelo.com/results/star-wars/minifigures/sw0759">(sw0759)</a>, which currently sits inside the top 40, was more visually interesting and is much more desirable amongst collectors.</p>
          </div>
         </div>,
    
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/star-wars/minifigures/sw0413">9. Darth Malgus (sw0413)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0413.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f87171"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg><span class="text-red-400">2</span>
              </div>
            </div>
            <p class="mb-3">This is the only minifigure of the Darth Malgus and is a fantastic looking minifigure for its time, having been released back in 2012. The printing on the head and shoulder/chest piece are fantastic and he has a nice fabric cape. The detailing on the legs and torso are also not too shabby. Darth Malgus has slipped down two places since last month, from 7th down to 9th.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/star-wars/minifigures/sw1273">8. Darth Vader (sw1273)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw1273.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">1</span>
              </div>
            </div>
            <p class="mb-3">The first of two Darth Vaders in the top ten is this version from 2023 who appeared in four sets. It's identical to the other Darth Vader in this list except it features a different head piece.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/star-wars/minifigures/sw0824">7. Moroff (sw0824)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0824.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f87171"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg><span class="text-red-400">1</span>
              </div>
            </div>
            <p class="mb-3">Moroff (sw0824) is one of the more distinctive and lesser known Star Wars minifigures. This mercenary appeared in Rogue One and was included in the Y-Wing Starfighter set from 2017. It has a unique head mould that comes all the way down to the legs and some excellent printing. This is definitely one I'd also have in my top ten.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/star-wars/minifigures/sw1222">6. Grand Inquisitor (sw1222)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw1222.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f87171"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg><span class="text-red-400">1</span>
              </div>
            </div>
            <p class="mb-3">The Grand Inquisitor is a minifigure from the Inquisitor Transport Scythe (75336) set released in 2022. The continuous print across the torso looks really nice and LEGO nailed the depiction of this character. It's not the most detailed, but it is very accurate to the source material.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/star-wars/minifigures/sw1141">5. Darth Vader (sw1141)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw1141.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#4ade80"
                    className="w-6 h-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg><span class="text-green-400">3</span>
              </div>
            </div>
            <p class="mb-3">With some many Darth Vader minifigure over the years, you'd expect LEGO to have perfected him by now, and arguably they have based on recent versions. This is one of many excellent Vaders LEGO have released and its hard to think of how it could be improved.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/star-wars/minifigures/sw1155">4. Darth Maul (sw1155)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw1155.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1 justify-center items-center">
                <div class="mx-1 h-1 w-4 bg-gray-500"></div>
                <span class="text-gray-400">0</span>
              </div>
            </div>
            <p class="mb-3">The highest rated Sith Lord is Darth Maul and this minifigure appeared in two sets between 2021 and 2022. It features some of the most impressive printing on any Darth Maul minifigure. The use of gun metal grey was a great choice and the section of red skin on this torso looks very cool.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/star-wars/minifigures/sw1233">3. Commander Cody (sw1233)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw1233.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1 justify-center items-center">
                <div class="mx-1 h-1 w-4 bg-gray-500"></div>
                <span class="text-gray-400">0</span>
              </div>
            </div>
            <p class="mb-3">This Phase 2 Commander Cody minifigure was long-awaited by Star Wars fans and LEGO finally delivered him in 2022. Cody's leg and torso printing look great and are very accurate to the source material. Undoubtedly the popularity of this character has helped propel this minifigure into the top three.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/star-wars/minifigures/sw0387">2. Padmé Amidala (sw0387)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0387.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1 justify-center items-center">
                <div class="mx-1 h-1 w-4 bg-gray-500"></div>
                <span class="text-gray-400">0</span>
              </div>
            </div>
            <p class="mb-3">The Padmé Amidala from 2012 is one of the most unique Star Wars minifigures out there and a firm fan favourite because of it. The headdress is brilliantly done and features some exquisite detail. Her long sweeping dress is also captured very well and there's some nice gold printing on the front of the torso. LEGO really went to town on this minifigure and I think it fully deserves its place in the top ten.</p>
          </div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400"><a href="https://brickelo.com/results/star-wars/minifigures/sw1219">1. Cad Bane (sw1219)</a></h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw1219.png" className="h-56 "/>
          <div class="flex flex-col gap-4">
            <div class="flex gap-1">
              <div class="font-bold">30 day Change:</div>
              <div class="flex gap-1 justify-center items-center">
                <div class="mx-1 h-1 w-4 bg-gray-500"></div>
                <span class="text-gray-400">0</span>
              </div>
            </div>
            <p class="mb-3">The latest Cad Bane minifigure from 2023 currently takes the top spot and has done so for some time now. This minifigure featured a new slim-lined hat piece that improved significantly over previous versions. The other notable upgrade was the ventilator that sits between the head and torso and joins seamlessly to the face. The head piece is also a nice metallic grey. There's some great printing across the whole minifigure and it's easily the best depiction of the legendary bounty hunter.</p>
          </div>
        </div>,
        <p class="mb-3">If you've enjoyed this article, you can check out the top 100 best Star Wars minifigures <a href="https://brickelo.com/results/star-wars" class="text-green-400 font-bold">here</a>.</p>,
      ]
    },
    {
      id: 6,
      title: 'How I Built the World\'s Largest Database of LEGO Minifigure Ratings',
      slug: 'how-i-built-the-worlds-largest-database-of-lego-minifigure-ratings',
      previewImg: 'building-brickelo.png',
      articleImg: 'building-brickelo2.png',
      alt: 'How I Built the World\'s Largest Database of LEGO Minifigure Ratings',
      summary: 'I\'ll start by saying this article isn\'t the shortest read, but hopefully an interesting one, as it\'s here that I explain how the idea for Brickelo came about, the development process, lessons learnt, and what the future might hold.',
      date: '6th November 2024',
      updated: '6th November 2024',
      author: 'Graham',
      content: [
        <h2 class="mb-3 font-bold text-xl text-green-400">It All Started with Mark Zuckerberg and FaceMash</h2>,
        <p class="mb-3">Whenever someone asks me about Brickelo, which very rarely happens I must admit, I begin by asking them if they've seen The Social Network. Perhaps the coolest scene from that movie is the one where we see Mark Zuckerburg build FaceMash - a website for rating female students at Harvard.</p>,
        <p class="mb-3">While the website was very questionable, the ELO algorithm behind it has always interested me, as it seemed like the most scientific approach to generating a ranked list of items. Far better than getting people to rate something on a numerical scale or getting people to pick their top five etc.</p>,
        <p class="mb-3">The ELO algorithm was first devised to rank chess players and it works by increasing or decreasing a player's rating based on whether they win or lose a match. The amount a player's rating is increased or decreased following the match outcome depends on the rating of their opponent prior to the match.</p>,
        <p class="mb-3">To give you an example from the tennis world, let's say Roger Federer plays the world number 100. Federer is arguably the greatest player of all time, so he would be expected to beat someone ranked much lower than himself, who is presumably much worse. Therefore, Federer's rating wouldn't increase much if he won the match, and likewise, his opponent's rating would only decrease slightly. Conversely, if the world number 100 beat Federer, the result suggests that Federer's opponent is better than him and far above the level of his current world ranking. As such, his rating would increase by a lot and Federer's would decrease by the same amount. Using this system, a player who's beaten 50 players ranked far below them can have a lower overall rating than a player who's played fewer matches but has beaten better opponents.</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400">Proof of Concept</h2>,
        <p class="mb-3">Outside of sports, I couldn't find many uses of the algorithm, so for a while I'd thought about applying it elsewhere. The world of LEGO was to provide me with a suitable opportunity. It's a hobby I'd been getting back into, largely because of my brother who was also undergoing a LEGO renaissance, and it had since become somewhat of an obsession. Together we built Brick Ranker, a website primarily aimed at tracking the value of LEGO sets and minifigures. It was while working on this project that I realised there was no definitive list of the “best” LEGO minifigures. On Brick Ranker we have lists of the most expensive minifigures, but are these people's favourites? I highly doubted there would be much correlation between the two and so the idea of putting together a robust list of the best LEGO minifigures was born. More than anything, I was curious to see how my personal favourites compared to everyone else's.</p>,
        <p class="mb-3">There are several community generated lists of the best LEGO sets, including our own at Brick Ranker, which is determined by our users' ratings of sets on a 10-point scale. Given that there are a lot more minifigures than sets, the disadvantage of using this system for minifigures is that it's likely that a large number of minifigures would go unrated. This is because most people will only rate something if they feel strongly about it (i.e., very good or very bad). Assigning a rating from 1 to 10 also takes time as people need to think about what each number means and potentially use their other ratings as a reference point. If you have thousands of minifigures to compare, you want users to be able to rate them as quickly as possible.</p>,
        <p class="mb-3">An ELO algorithm on the other hand seemed like a perfect approach, as unlike sets, a judgement on which of two minifigures is better can be made by just looking at them. Selecting your favourite of two minifigures is also simple and can be done very quickly. This low barrier to entry was going to be key to making the site a success because I would need a lot of data to generate robust results.</p>,
        <p class="mb-3">If we take a look at the Bricklink catalogue, there are around 17,000 minifigures at the time of writing. Not all of these are minifigures in the traditional sense, as according to LEGO, minifigures are those that use at least two of the three standard head, torso, and legs parts. This official definition technically rules out most Star Wars droids, skeletons, and baby minifigures to name but a few. More clear cut exclusions are Duplo figures, mini-doll figures (i.e. figures from LEGO Friends, Elves, etc), and brick-built figures.</p>,
        <p class="mb-3">Let's assume that if we exclude all these we're left with 10,000 minifigures for users to compare (although in reality this figure is much higher). This equates to just under 5 million different 1 vs. 1 combinations. To get robust ratings, we'd want each minifigure to be compared against every other minifigure multiple times, meaning several million user ratings are needed. At the time of writing we have just over half a million ratings, meaning that in theory around 10% of the possible combinations have occurred. I soon realised this was one of the main challenges in using an ELO algorithm to rank such a large dataset.</p>,
        <p class="mb-3">However, generating that level of data was not unachievable. As I mentioned earlier, I made the barrier to entry as low as possible, so a user can visit the site and within five minutes have easily compared 50 minifigures. The potential audience that would be interested in using the tool is also very large. The LEGO subreddit has nearly 2 million members, and Brickset, one of the largest LEGO websites, received nearly 12 million visitors last year. Of course, I could only dream of that level of traffic for Brickelo, but my point is the LEGO community is large enough for this approach to work.</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400">Designing Brickelo</h2>,
        <p class="mb-3">With the proof of concept in place, the next step was to build the thing. I had no web development experience, except for some brief dabbling in HTML, CSS, and Javascript. My input into Brick Ranker was primarily non-technical, so this would be my first solo project. My brother is a web developer by profession, so fortunately I could ask him for some steer. He recommended using a JavaScript framework called React, which has gained popularity in recent times, and is used by many large organisations. Amongst its benefits are its relative simplicity and its speed, so it seemed a good choice. Little did I know, there were large drawbacks that might have affected my choice of framework had I known these from the beginning.</p>,
        <p class="mb-3">As I was starting from almost zero, it was a couple of months before I had the first version of the website ready to launch. A large part of that time was spent learning React and putting together the backend, but a considerable amount of time was also spent thinking about how the website should look. I settled on a dark theme as this brought the minifigures into focus. I also chose to present the user with the minimum amount of information when asking them to compare minifigures as I want to remove as much bias as possible.</p>,
        <p class="mb-3">Initially, I planned to present users with two minifigures randomly selected from the whole database. This meant they would often be presented with quite obscure minifigures to choose between. I thought this might put some people off because if someone is only interested in Star Wars minifigures, they could get fed up if they don't see these, given how large the database was. I decided to add the ability to filter based on popular themes, such as Pirates of the Caribbean, The Lord of the Rings, and The Hobbit, so users could see their favourites more often.</p>,
        <p class="mb-3">By adding this feature, minifigures from the aforementioned themes would appear in far more matchups than average and therefore would have an advantage. Ideally, you want all minifigures to have undergone a similar number of matchups because minifigures that have undergone more matchups may have a higher rating just because they've made more opportunities to accumulate points and not because they are better than those that have undergone fewer matchups. In theory, this is only a short-term problem, because there will be a threshold number of matchups, after which a minfigure's rating stabilises. I had no idea what this threshold number was though.</p>,
        <p class="mb-3">I had a lot of fun in the testing phase of the project, which I thought was a good sign. I found making comparisons quite addictive and I realised it was a great tool for discovering minifigures. My LEGO knowledge was pretty good, but I was coming across minifigures I never knew existed. Once I'd made sure everything worked, it was time to let everyone else know about it…</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400">Going Live!</h2>,
        <p class="mb-3">Brickelo went live in August 2023, much to my pride and excitement. I believed I'd created something brilliant and naturally assumed it would instantly take off. However, August turned to November without any traffic whatsoever and I quickly learnt that you can build the best website in the world, but if you don't tell anyone about it, no one will use it.</p>,
        <p class="mb-3">I needed to get the word out somehow, but I didn't have any social media presence or the know-how or motivation to build one. I tried Hacker News, but my post went largely unnoticed. I tried emailing LEGO employees, which I thought was a genius tactic, and while it generated some traffic there was close to zero engagement. I found it odd and disenchanting that LEGO employees have no interest in LEGO. Reddit did however prove to be a happy hunting ground. I posted several times and on each occasion got a lot of positive and encouraging feedback. One post led to around 2,700 visitors and around 210,000 ratings.</p>,
        <p class="mb-3">As Brickelo celebrated its 1-year anniversary, there were a little over half a million ratings. This seems like a large number, but I was hoping the figure would be at least double that. However, it is enough to have produced some compelling results. The top 100 list also seemed to be very credible and I was pleased to see it wasn't dominated by the most expensive or minifigures of the most popular characters. It does feel like there's an over presentation of minifigures from the Pirates of the Caribbean, The Lord of the Rings, and The Hobbit and it will be interesting to see if this stays that way.</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400">The Road Ahead</h2>,
        <p class="mb-3">To bring long term success, I needed the site to be self-sustaining. Unfortunately, React isn't an SEO friendly framework. Without going too much into the technical details, very little of the website's content is pre-rendered, meaning it's effectively invisible to search engines. This meant that except for times when I posted on Reddit, on a fairly good day, I was getting around 10 users and a couple of hundred ratings and there'd been little change over time. This led me to give up a little on the project at times as it felt like flogging a dead horse, so since I launched Brickelo, I've mainly just kept it up-to-date and haven't rolled out many new features.</p>,
        <p class="mb-3">However, I cared too much to drop Brickelo completely so I went away and learnt about SEO and what I could do to make my site more visible, without the need to completely rebuild it. With a regular user base I believe Brickelo could become a powerful tool and I'd love to see what kind of results are produced from significantly more data. I have a lot more developments planned, and it feels like Brickelo is just at the beginning of its journey, so watch this space…</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400">Final Thoughts</h2>,
        <p class="mb-3">I'll finish by saying that I started this with the main aim of finding out if other AFOLs shared my opinion on which LEGO minifigures are the best. My own list of favourites has evolved over the process of building Brickelo, but by and large I agree most minifigures occupying the top 100 deserve their place there. Where exactly my top 10 rank is an article for another day…</p>
      ]
    },
    {
      id: 5,
      title: 'Every LEGO Collectible Minifigure Series From Best to Worst',
      slug: 'every-lego-collectible-minifigure-series-from-best-to-worst',
      previewImg: 'lego-cmf-best-to-worst.png',
      articleImg: 'lego-cmf-best-to-worst2.png',
      alt: 'LEGO Collectible Minifigures',
      summary: 'Back in February, I wrote an article for Brick Ranker that looked at the average rating of minifigures within each Collectible Minifigure (CMF) series to see which was the worst and which was the best. Since then, two more series have been released and the number of user ratings in our database has doubled, with over half a million to date. Therefore, I thought this a good time to provide an updated analysis.',
      date: '3rd November 2024',
      updated: '3rd November 2024',
      author: 'Graham',
      content: [
        <p class="mb-3">Back in February, I wrote an article for <a href="https://brickranker.com/reviews/are-collectible-minifigure-series-getting-worse" class="text-green-400 font-bold">Brick Ranker</a> that looked at the average rating of minifigures within each Collectible Minifigure (CMF) series to see which was the worst and which was the best. Since then, two more series have been released and the number of user ratings in our database has doubled, with over half a million to date. Therefore, I thought this a good time to provide an updated analysis.</p>,
        <p class="mb-3">46 CMF series have been released so far, including 26 non-licensed series and 20 licensed. The chart below shows the average rating of each of the 46 series from best to worst, with the white line showing the average rating of all series combined.</p>,
        <div><img src="/images/minifigures/blogs/Chart2.PNG" alt="Chart of average LEGO Collectible Minifigure ratings from worst to best" className="h-full object-contain"/></div>,
        <h2 class="mb-3 font-bold text-xl text-green-400">Worst 10</h2>,
        <p class="mb-3">At the bottom end of the scale we have the Deutscher Fussball-Bund (German Football Association) series from 2016. It's fair to say this is the most mundane series, as each minifigure is very similar and unless you're a big German football fan, the series has little appeal. Second from bottom is the other sport-themed series - Team GB. There's a greater diversity of minifigures in this series, but it's still not the most exciting series and its average rating is well below the average. </p>,
        <p class="mb-3">The next worst is the first Harry Potter series, which had no standout minifigures and didn't introduce anything that different from regular Harry Potter minifigures. Making up the rest of the bottom five are the two Simpsons series, which is not surprising as there's a certain awkwardness about the Simpsons minifigures.</p>,
        <p class="mb-3">The second Harry Potter series is sixth from bottom, then we have Looney Tunes followed by the Muppets. Series 2 and Series 3 round out a bottom ten that's dominated by licensed series.</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400">Top 10</h2>,
        <p class="mb-3">Moving to the other end of the spectrum, we have a pretty balanced mix of non-licensed and licensed series in the top 10. In tenth is Series 12, which includes the <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col179" class="text-green-400 font-bold">Wizard</a> and <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col183" class="text-green-400 font-bold">Battle Goddess</a>, both of which are very highly rated. However, it also has the <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col189" class="text-green-400 font-bold">Pizza Delivery Guy</a> and the <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col182" class="text-green-400 font-bold">Video Game Guy</a>, which very much felt like they were making up the numbers.</p>,
        <p class="mb-3">In ninth is DC Super Heroes, which lacked any headline minifigures, but doesn't have any notably bad ones. Eighth place belongs to Series 9, where the <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col132" class="text-green-400 font-bold">Heroic Knight</a> and <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col130" class="text-green-400 font-bold">Cyclops</a> were the best and the <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col134" class="text-green-400 font-bold">Policeman</a> and <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col136" class="text-green-400 font-bold">Roller Derby Girl</a> were clearly the least popular.</p>,
        <p class="mb-3">Series 15 had four minifigures everyone wanted in the form of the <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col230" class="text-green-400 font-bold">Frightening Knight</a>, the <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col243" class="text-green-400 font-bold">Queen</a>, the <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col233" class="text-green-400 font-bold">Flying Warrior</a>, and the <a href="https://brickelo.com/results/collectable-minifigures/minifigures/col234" class="text-green-400 font-bold">Faun</a>. These helped propel it into 7th place. Just outside the top 5 is the LEGO Batman Movie Series 1 where <a href="https://brickelo.com/results/collectable-minifigures/minifigures/coltlbm18" class="text-green-400 font-bold">Calculator</a>, <a href="https://brickelo.com/results/collectable-minifigures/minifigures/coltlbm02" class="text-green-400 font-bold">Glam Metal Batman</a>, and <a href="https://brickelo.com/results/collectable-minifigures/minifigures/coltlbm14" class="text-green-400 font-bold">Orca</a> are all rated highly.</p>,
        <p class="mb-3">Now let's take a look at the top five series in more detail.</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400">5. Series 13</h2>,
        <p class="mb-3">Series 13 is in fifth place and is helped by the Classic King and Evil Wizard, who are among the top rated CMFs ever released.</p>,
        <div class="grid grid-cols-4 md:grid-cols-6 gap-4 justify-center items-center mb-6">
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="16"><img src="/images/minifigures/col203.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="15"><img src="/images/minifigures/col207.png" className="h-full object-contain "/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="14"><img src="/images/minifigures/col197.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="13"><img src="/images/minifigures/col198.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="12"><img src="/images/minifigures/col200.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="10"><img src="/images/minifigures/col205.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="9"><img src="/images/minifigures/col206.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="8"><img src="/images/minifigures/col210.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="7"><img src="/images/minifigures/col202.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="6"><img src="/images/minifigures/col209.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="5"><img src="/images/minifigures/col196.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="4"><img src="/images/minifigures/col208.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="3"><img src="/images/minifigures/col199.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="2"><img src="/images/minifigures/col195.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="1"><img src="/images/minifigures/col204.png" className="h-full object-contain"/></div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400">4. Marvel Studios Series 1</h2>,
        <p class="mb-3">Marvel Studios Series 1 is in fourth, where Monica Rambeau and Sylvie are the only two minifigures with a below average rating (i.e. less than 1000).</p>,
        <div class="grid grid-cols-4 md:grid-cols-6 gap-4 justify-center items-center mb-6">
          <div class="figure flex justify-center items-center h-24 md:h-32 " data-number="12"><img src="/images/minifigures/colmar03.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32 " data-number="11"><img src="/images/minifigures/colmar07.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32 " data-number="10"><img src="/images/minifigures/colmar11.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32 " data-number="9"><img src="/images/minifigures/colmar06.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="8"><img src="/images/minifigures/colmar04.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="7"><img src="/images/minifigures/colmar12.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="6"><img src="/images/minifigures/colmar09.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="5"><img src="/images/minifigures/colmar10.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="4"><img src="/images/minifigures/colmar01.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="3"><img src="/images/minifigures/colmar05.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="2"><img src="/images/minifigures/colmar02.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="1"><img src="/images/minifigures/colmar08.png" className="h-full object-contain"/></div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400">3. Marvel Studios Series 2</h2>,
        <p class="mb-3">The second Marvel Studios series is considered better than the first and I have to agree, as it contained several fantastic minifigures. The Moon Knight is one of the very best CMFs ever released in my opinion, but the LEGO community has declared Wolverine as the best from this series. Hawkeye was the only real dud in this series.</p>,
        <div class="grid grid-cols-4 md:grid-cols-6 gap-4 justify-center items-center mb-6">
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="12"><img src="/images/minifigures/colmar18.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="11"><img src="/images/minifigures/colmar20.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="10"><img src="/images/minifigures/colmar17.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="9"><img src="/images/minifigures/colmar21.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="8"><img src="/images/minifigures/colmar16.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="7"><img src="/images/minifigures/colmar19.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="6"><img src="/images/minifigures/colmar15.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="5"><img src="/images/minifigures/colmar22.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="4"><img src="/images/minifigures/colmar13.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="3"><img src="/images/minifigures/colmar23.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="2"><img src="/images/minifigures/colmar14.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="1"><img src="/images/minifigures/colmar24.png" className="h-full object-contain"/></div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400">2. Series 26</h2>,
        <p class="mb-3">Series 26 was the second non-licensed series to be themed, being centered around Space. It included modern reimaginings of three classic Space themes: M-Tron, Ice Planet, and Blacktron, which pleased a lot of older LEGO fans. This series definitely marked a return to form for Collectible Minifigures.</p>,
        <div class="grid grid-cols-4 md:grid-cols-6 gap-4 justify-center items-center mb-6">
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="12"><img src="/images/minifigures/col436.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="11"><img src="/images/minifigures/col437.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="10"><img src="/images/minifigures/col441.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="9"><img src="/images/minifigures/col446.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="8"><img src="/images/minifigures/col438.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="7"><img src="/images/minifigures/col439.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="6"><img src="/images/minifigures/col445.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="5"><img src="/images/minifigures/col448.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="4"><img src="/images/minifigures/col447.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="3"><img src="/images/minifigures/col440.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="2"><img src="/images/minifigures/col444.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="1"><img src="/images/minifigures/col443.png" className="h-full object-contain"/></div>
        </div>,
        <h2 class="mb-3 font-bold text-xl text-green-400">1. Series 14</h2>,
        <p class="mb-3">The other themed non-licensed series takes the top spot and the title of 'Best LEGO Collectible Minifigure Series'. This was Series 14, where each minifigure was a monster of some kind. This series is currently considered the best by a comfortable margin, which is down to the fact that it had no bad minifigures. The 'least good' is the Zombie cheerleader, which still looks quite cool.</p>,
        <div class="grid grid-cols-4 md:grid-cols-6 gap-4 justify-center items-center mb-6">
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="16"><img src="/images/minifigures/col218.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="15"><img src="/images/minifigures/col223.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="14"><img src="/images/minifigures/col219.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="13"><img src="/images/minifigures/col222.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="12"><img src="/images/minifigures/col215.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="11"><img src="/images/minifigures/col211.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="10"><img src="/images/minifigures/col214.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="9"><img src="/images/minifigures/col224.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="8"><img src="/images/minifigures/col216.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="7"><img src="/images/minifigures/col225.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="6"><img src="/images/minifigures/col212.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="5"><img src="/images/minifigures/col221.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="4"><img src="/images/minifigures/col213.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="3"><img src="/images/minifigures/col226.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="2"><img src="/images/minifigures/col220.png" className="h-full object-contain"/></div>
          <div class="figure flex justify-center items-center h-24 md:h-32" data-number="1"><img src="/images/minifigures/col217.png" className="h-full object-contain"/></div>
        </div>,
        <p class="mb-3">The much anticipated Dungeons & Dragons series currently sits mid-pack, but it's only recently been added to the database and some time is needed for the D&D minifigures to be compared enough times for them to be given a representative rating. Therefore, I expect this series to move towards the top 5 shortly, given how well it was received.</p>,
        <h2 class="mb-3 font-bold text-xl text-green-400">Are CMFs Getting Better?</h2>,
        <p class="mb-3">Lastly, I thought I'd arrange the average ratings of each series in the order they were released to see if they're getting better over time. On the one hand, with developments in printing and moulding, you'd expect this to be true, but on the other hand, it's felt like LEGO have been running out of ideas for new non-licensed CMFs. The white line below shows a very slight improvement over the years, so CMFs are improving.</p>,
        <div><img src="/images/minifigures/blogs/Chart3.PNG" alt="Chart of average LEGO Collectible Minifigure ratings order by release date" className="h-full object-contain"/></div>,
        <p class="mb-3">That's all for this article. If you want to vote on your favourite CMFs you can do so <a href="https://brickelo.com/ranking/collectable-minifigures" class="text-green-400 font-bold">here</a>, or if you want to view the top 100 CMFs you can do so <a href="https://brickelo.com/results/collectable-minifigures" class="text-green-400 font-bold">here!</a></p>
      ]
    },
    {
      id: 4,
      title: 'Top Ten Worst LEGO Star Wars Minifigures',
      slug: 'top-ten-worst-lego-star-wars-minifigures',
      previewImg: 'worst-star-wars-minifigures.png',
      articleImg: 'worst-star-wars-minifigures2.png',
      alt: 'Worst LEGO Star Wars Minifigures',
      summary: 'LEGO Star Wars has given us some of the very best LEGO minifigures out there, with excellent printing and detailed moulds seen across the theme. However, there are some Star Wars minifigures LEGO didn\'t get right, or which are clearly showing signs of age when compared to modern standards. In this article, I take a look at the worst Star Wars minifigures, based on the more than half a million user ratings that have been submitted so far.',
      date: '31st October 2024',
      updated: '31st October 2024',
      author: 'Graham',
      content: [
        <p class="mb-3">LEGO Star Wars has given us some of the very best LEGO minifigures out there, with excellent printing and detailed moulds seen across the theme. However, there are some Star Wars minifigures LEGO didn't get right, or which are clearly showing signs of age when compared to modern standards.</p>,
        <p class="mb-3">In this article, I take a look at the worst Star Wars minifigures, based on the more than half a million user ratings that have been submitted so far.</p>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">10. R5-D4 (sw0142)</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0142.png" className="h-56 "/>
          <p class="">While not technically minifigures, astromech droids are generally considered to be part of the Star Wars minifigure family. There are currently well over 50 astromech droids and this one of R5-D4 is the worst of the bunch. R5-D4 has a less dome-like head than other droids, such as R2-D2, and so really deserves a unique mould to capture its harder edges. Instead, LEGO opted to use a round 2x2 tan plate, which doesn't work on any level. The lack of printing on the “head” piece also makes this minifigure look half finished.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">9. Rey (sw0888)</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0888.png" className="h-56 "/>
          <p class="">Like a few minifigures on this list, there's nothing glaringly wrong with this one, it just lacks any excitement and interest. The detailing isn't bad and it does a decent job of representing the character's rather plain attire. Not every minifigure can grab our attention and this is one such example.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">8. Resistance Officer (Major Brance) (sw0876)</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0876.png" className="h-56 "/>
          <p class="">Major Brance was a rather forgettable character that appeared in Episode VII: The Force Awakens. Considering this minifigure was released in 2017, it also lacks a lot of detail. The two factors are undoubtedly why it finds itself in the bottom 10.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">7. Resistance Soldier (sw0669)</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0669.png" className="h-56 "/>
          <p class="">The second member of the Resistance in this list is this generic soldier that was released in the 2015 First Order Transporter (75103) set. Again, this minifigure doesn't have a lot of printing, but also the brown uniforms of the Resistance don't make for the most appealing minifigures.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">6. Crix Madine (sw0250)</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0250.png" className="h-56 "/>
          <p class="">One of the earliest minifigures in the bottom ten is Crix Madine from 2009. There's a whole lot of sand yellow and sand blue going in here and the minifigure uses one of the most common hairpiece moulds. Therefore, it's a very plain looking minifigure so it's no wonder it's rated so low.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">5. Republic Captain (sw0169)</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0169.png" className="h-56 "/>
          <p class="">The Republic Captain is the oldest minifigure on this list, which came in the 2007 Republic Cruiser (7665). There's a certain nostalgia to these more classic looking minifigures (although 2007 is far from being considered vintage LEGO), and as a result, many of the older Space and Castle minifigures are rated quite highly. However, this doesn't seem to have translated over the Star Wars theme.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">4. Kai Brightstar (sw1268)</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw1268.png" className="h-56 "/>
          <p class="">Now from the oldest to the newest minifigure on this list. In 2023, LEGO released the first Star Wars Young Jedi Adventures sets, which were aimed very much at children. The minifigures in these sets therefore have less appeal to older LEGO fans, which explains why Kai Brightstar is considered one of the worst Star Wars minifigures. The hairpiece used also doesn't do it any favours.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">3. Young Han Solo (sw0357)</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0357.png" className="h-56 "/>
          <p class="">This minifigure didn't appear in any regular sets and instead was included as a freebie with two Star Wars DVDs. A minifigure of young Han Solo is never going to be as desirable as one of adult Han Solo, and there's hardly any printing to speak of.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">2. Rowan (sw0851)</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0851.png" className="h-56 "/>
          <p class="">Rowan is a character from Star Wars: The Freemaker Adventures, which is an animated TV series. Rowan Freemaker is the central character, and while there's nothing wrong with this minifigure, it's not a character AFOLs can get excited about, which is why it ranks so far down. In general, minifigures depicting children are rated much lower than their adult counterparts.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">1. Padme Amidala (sw0411)</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sw0411.png" className="h-56 "/>
          <p class="">The worst LEGO Star Wars minifigure, as voted by the LEGO community, is the Padme Amidala minifigure from the Malevolence set released in 2012. You just have to look at the headpiece to understand why it took the bottom step. It was supposed to represent the animated character version from The Clone Wars, hence the weird eyes, but the result is a very strange looking minifigure.</p>
        </div>,
        <p class="mb-3">Don't agree with this list? Maybe you think <a href="https://brickelo.com/results/star-wars/minifigures/sw0243" class="text-green-400 font-bold">Palpatine (sw0243)</a> should be there? Well as a reminder, this list isn't static and you can have your say about which Star Wars minifigures are the best or the worst <a href="https://brickelo.com/ranking/star-wars" class="text-green-400 font-bold">here</a>.</p>

    ]},
    {
      id: 3,
      title: 'Every LEGO Comic-Con Minifigure Ranked From Best to Worst',
      slug: 'every-lego-comic-con-minifigure-ranked-from-best-to-worst',
      previewImg: 'comic-con-minifigures.png',
      articleImg: 'comic-con-minifigures2.png',
      alt: 'LEGO Comic-Con Exclusive Minifigures',
      summary: 'From 2011 to 2019 LEGO brought limited edition minifigures to Comic-Con events in San Diego and New York, which were only available to convention attendees. The method of obtaining them varied, but typically it involved a lottery system where participants had differing odds of success. With production runs of 200 - 1,750, Comic-Con exclusives are some of the rarest minifigures ever made and are highly sought after.',
      date: '28th October 2024',
      updated: '28th October 2024',
      author: 'Graham',
      content: [
        <p class="mb-3">From 2011 to 2019 LEGO brought limited edition minifigures to Comic-Con events in San Diego and New York, which were only available to convention attendees. The method of obtaining them varied, but typically it involved a lottery system where participants had differing odds of success. With production runs of 200 - 1,750, Comic-Con exclusives are some of the rarest minifigures ever made and are highly sought after.</p>,
        <p class="mb-3">But putting value and rarity aside, how objectively good are these exclusives? In this article I take a look at the rating of each of the 25 exclusives from best to worst and how they compare with regular minifigures.</p>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">25. Barbara Holland - Barb</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/st009.png" className="h-56 "/>
          <p class="">The “worst” Comic-Con minifigure is the only non-superhero exclusive, which was given out at the 2019 San Diego Comic-Con. Barb is a character from Stranger Things, and it acted as promotion material for LEGO's one and only Stranger Things set, the Upside Down. I like the striking, yet inaccurate, pink torso and overall look of this minifigure and I don't think it deserves to come in last place, but it is one of the less exciting characters LEGO brought to Comic-Con.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">24. Shazam</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh042.png" className="h-56 "/>
          <p class="">Shazam was one of the earlier Comic-Con minifigures from 2012 and it shows through in the lack of detail. This marked the character's first appearance and he has since only appeared three other times in minifigure form.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">23. Vixen</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh434.png" className="h-56 "/>
          <p class="">This minifigure was given out at the San Diego Comic-Con in 2019 and depicts the superheroine from the Legends of Tomorrow TV series. Vixen is one of the lesser known characters from the DC universe and this is the only minifigure to date of this character. There's some nice detail, but the colour palette is somewhat understated, so I'd also place this minifigure low down in the list.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">22. Green Lantern</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh001.png" className="h-56 "/>
          <p class="">The final exclusive with a below average rating is the Green Lantern. This was the one of the first Comic-Con exclusives, having been handed out at the 2011 San Diego event. The figure lacks any detail on its legs and the torso and face printing is nothing special.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">21. Captain America - Sam Wilson</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh208.png" className="h-56 "/>
          <p class="">The remaining minifigures on this list all have a rating above the average and the first of these is Sam Wilson as Captain America, which was given out at the 2015 San Diego Comic-Con. While the costume is accurate to the source material, it's not one of the most aesthetically pleasing, so I can see why this one ranked low down. LEGO did a far more detailed and realistic version of this character in Series 1 of the Marvel Studios Collectible Minifigure series, which highlights how basic this minifigure is.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">20. Spider-Woman</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh140.png" className="h-56 "/>
          <p class="">Making the top 20 is Spider-Woman who is one of the most valuable Comic-Con minifigs, due to it being one of the rarest - 350 copies - and the only representation of the character to date in LEGO form. Although it's showing slight signs of age, having been released back in 2013, it has one of the most striking colour schemes.
          </p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">19. Captain America -Steve Rogers</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh295.png" className="h-56 "/>
          <p class="">The second Captain America Comic-Con exclusive was distributed in 2016, the year after the Sam Wilson version. This depicts the superhero as we're more used to seeing him, as Steve Rogers has featured more prominently in the MCU universe, so it's not surprising this version is the favoured of the two.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">18. Batman</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh002.png" className="h-56 "/>
          <p class="">Along with the Green Lantern, this was one of the first Comic-Con exclusive minifigures. By modern standards, this is a very plain depiction of the caped crusader, so it's no wonder this is one of the lowest rated Batman minifigures.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">17. Zebra Batman</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh604.png" className="h-56 "/>
          <p class="">Zebra Batman is one of the most unusual Comic-Con exclusives, and one of my favourites, so personally, I would rate this minifigure much higher. Although it doesn't have a ton of detail, the black and white creates a great effect. However, when it was announced, many LEGO fans were a bit nonplussed as it depicts a little known superhero and not a variant of Batman as the name would suggest.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">16. Black Lightning</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh521.png" className="h-56 "/>
          <p class="">Black Lighting one was of the later exclusives, being handed out in 2018, and has a more modern look than many of the others. The blue and yellow details look great against the black outfit and it really makes them stand out.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">15. The Kraang</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/tnt021.png" className="h-56 "/>
          <p class="">Moving into the top 15 now and we have The Kraang. This was one of the few exclusives given out in New York at the Rockefeller store during the 2012 convention. At the time, this was the first minifigure of the supervillain, but there have since been three others.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">14. Green Arrow</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh138.png" className="h-56 "/>
          <p class="">The Green Arrow is one of the rarest Comic-Con exclusives - 200 copies - and therefore it's one of the most expensive LEGO minifigures you can buy. It's good to see that this hasn't influenced its rating as it sits outside the top 2,000 at the time of writing.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">13. Arsenal</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh207.png" className="h-56 "/>
          <p class="">Arsenal is a similar looking minifigure to the Green Arrow so it makes sense that they have very similar ratings. Again, LEGO chose to bring quite an obscure character to Comic-Con, which I think confused some LEGO fans at the time.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">12. Shadow Leonardo</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/tnt001.png" className="h-56 "/>
          <p class="">On to number 12 and one of the coolest Comic-Con exclusives, which was given out in both San Diego and New York. It represents a “dark form” of Leonardo, the leader of the Teenage Mutant Ninja Turtles, and was a character LEGO invented. Great job LEGO. If it was just up to me, I'd rate this minifigure much higher than it is today, although to be fair it's not the most detailed. This minifigure also marked the beginning of the LEGO Teenage Mutant Ninja Turtles theme.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">11. Batman of Zur-En-Arrh</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh129.png" className="h-56 "/>
          <p class="">Another obscure superhero was brought to Comic-Con in 2014 with the Batman of Zur-En-Arrh. This colourful character appears in the LEGO Batman 3: Beyond Gotham video game that was released later that same year, which is why LEGO chose him to be an exclusive.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">10. Phoenix</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh044.png" className="h-56 "/>
          <p class="">Just making the top ten is Jean Grey as Phoenix. I really like the yellow and green colour combination on this minifigure and the burst of red from her hair. At the time of writing, Phoenix was just outside the top 1,000 “best” minifigures. This was released during the second year of Comic-Con exclusives in 2012.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">9. ATOM</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh293.png" className="h-56 "/>
          <p class="">ATOM appears only once in minifigure form and can be considered the DC equivalent of Ant-Man. This is one of the most detailed exclusives and the only one to come with its own baseplate.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">8. Spider-Man in Black Symbiote Costume</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh045.png" className="h-56 "/>
          <p class="">The first of the three Spider-Man exclusives comes in at number 8, which is not all that surprising given he's generally the most popular superhero. This minifigure probably doesn't deserve to be this highly rated, but I guess there's beauty in its simplicity.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">7. Spider-Man</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh139.png" className="h-56 "/>
          <p class="">This was the second Spider-Man exclusive minifigure and was given out at the 2013 San Diego Comic-Con. It's based on Andrew Garfield's portrayal of the character and regularly features at the top of the list of most expensive LEGO minifigures. It has some fantastic detailing, especially the printing of the eyes.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">6. PS4 Spider-Man</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh603.png" className="h-56 "/>
          <p class="">The last Spider-Man minifigure to come out at Comic-Con was the PS4 version in 2019. This depicts the superhero in his advanced suit seen in the Playstation 4 video game. The cartoony printing gives this minifigure a unique look and it has to be my favourite of all the Comic-Con exclusives.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">5. Bizarro</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh043.png" className="h-56 "/>
          <p class="">Bizarro is not one of the Comic-Con exclusives I expected to be in the top 5. The minifigure features quite simple printing, but the headpiece is very unusual and it's one of the coolest exclusives.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">4. Deadpool Duck</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh427.png" className="h-56 "/>
          <p class="">This is a minifigure I never thought we'd see and Comic-Con was probably the only opportunity LEGO would have got to give us this crazy mash-up. It's unique and fun and therefore a firm fan favourite.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">3. Superman</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh137.png" className="h-56 "/>
          <p class="">Not much is cool about Superman except when you put him in a black suit. Along with the Green Arrow, this is one of the rarest exclusives as only 350 copies were distributed.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">2. The Collector</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh130.png" className="h-56 "/>
          <p class="">At number 2 is the only Guardians of the Galaxy exclusive. The Collector was given out in 2014 and is the only minifigure of this character. The colours used for his waistcoat look really nice and the white hair and cloak provide some nice contrast. There are many other Comic-Con exclusives I would place before him though so I'm surprised he's so far up the list.</p>
        </div>,
        <h2 class="text-green-400 mb-2 font-bold text-lg">1. Sheriff Deadpool</h2>,
        <div className="mb-10 flex flex-col sm:flex-row gap-6 sm:gap-16 justify-start items-start">
          <img src="/images/minifigures/sh520.png" className="h-56 "/>
          <p class="">Coming in at number 1 is Sheriff Deadpool is considered the best Comic-Con exclusive and it also rates as one of best LEGO minifigures as it's often found in the top 20. It's definitely on the most detailed exclusives and was a neat idea for a Deadpool variant. I have to agree that it's deserving of its place on the top step.</p>
        </div>,
        <p class="mb-3">If you've enjoyed this article you can check out other <a href="https://brickelo.com/blog" class="text-green-400 font-bold">deepdives</a> into the Brickelo database or vote on your favourite Comic-Con minifigures <a href="https://brickelo.com/ranking" class="text-green-400 font-bold">here</a>.</p>
      ]
    },
    {
      id: 2,
      title: 'How Good Are LEGO Minifigures in 2024?',
      slug: 'how-good-are-lego-minifigures-in-2024',
      previewImg: '2024-minifigures.png',
      articleImg: '2024-minifigures-2.png',
      alt: 'LEGO 2024 minifigures',
      summary: 'With every year that goes by, LEGO seems to get ever more creative with the minifigure blueprint by bringing out increasingly complex and creative moulds. Over time, we’ve also seen more detailed minifigure printing and a wider variety of colours. With all the advancements in minifigure design, are minifigures released today considered better than those released ten, twenty, or thirty years ago? To answer this question, I took a look at the average minifigure rating for each year and the results were pretty surprising.',
      date: '27th October 2024',
      updated: '27th October 2024',
      author: 'Graham',
      content: [
        <p class="mb-3">With every year that goes by, LEGO seems to get ever more creative with the minifigure blueprint by bringing out increasingly complex and creative moulds. Over time, we've also seen more detailed minifigure printing and a wider variety of colours. With all the advancements in minifigure design, are minifigures released today considered better than those released ten, twenty, or thirty years ago?</p>,
        <div><img src="/images/minifigures/blogs/Chart.PNG" alt="Chart of average LEGO minifigure ratings by year" className="h-full object-contain"/></div>,
        <p class="mb-3">I was expecting to see a gradual increase in average rating as we got nearer the present day, but instead the results show certain "golden" periods in minifigure design. Obviously, what people consider a good minifigure and what they consider a less good minifigure is influenced to some extent by which character the minifigure is representing. Although I've tried to remove as much bias as possible in the ranking process, this will always be a factor. As a result, the years when some of the most popular themes were around score highly. This explains why we see 2011 -  2014 being some of the highest rated, as the Lord of the Rings, Hobbit, and Pirate of the Caribbean sets were released then and minifigures from these themes have a strong presence in the <a href="https://brickelo.com/results" class="text-green-400 font-bold">top 100</a>.</p>,
        <div className="h-80 float-left mx-4 flex flex-col justify-between items-center">
          <img src="/images/minifigures/lor021.png" className="h-full"/>
          <p className="text-sm"><a href="https://brickelo.com/results/the-lord-of-the-rings/minifigures/lor021" class="text-green-400 font-bold">King Théoden</a> - Highest rated minifigure from 2012</p>
        </div>,
        <p class="mb-3">However, LoTR, Hobbit, and PotC minifigures are not solely responsible for these years being considered some of the best for minifigures. There were also some much sought after Collectible Minifigures released in those years, such as the Minotaur, the Conquistador, the Roman Commander, Hazmat Guy, and Medusa to name but a few.</p>,
        <p class="mb-3">The more unexpected result is the popularity of minifigures released during the late 1980s through to the late 1990s, which culminates in 1998 being the “best” year for minifigures. So what made these years so good? </p>,
        <p class="mb-3">Taking a deeper look into the data reveals that the classic Castle and Spaces minifigures score well. There's also the fact that far fewer minifigures were released back then, so you just need a handful of good ones to really bring up the average. I should mention here that some of the minifigures from those years, such as City/Town ones, are not in the database yet and these might bring down the average. Regardless, I think these findings highlight that the nostalgia of themes from yesteryear are a strong determinant in people's favourite minifigures.</p>,
        <p class="mb-3">Looking at the other end of the scale, the worst years were between 2001 and 2004. This saw the release of the first licensed themes in the form of LEGO Star Wars and LEGO Harry Potter. While licensed minifigures tend to score better than unlicensed ones, let's be honest, the early minifigures from these themes were awful, and they're among the worst rated minifigures in the database.</p>,
        <p class="mb-3">Moving on to the present day, I thought 2024 would be considered one of the better years for minifigures but it scores below average, like the previous six years. We've had some fantastic releases this year, such as the new LoTR minifigures and two well received CMF series with Space and Dungeons & Dragons, and personally I think we've been spoiled for choice this year. I expect the average rating for 2024 will increase over time as the newer minifigures in the database get time to enter into more match ups so I'll provide an update in a few months.</p>,
        <p class="mb-2">Thanks for reading, and if you want to have you're say on which minifigures are the best, you can vote for your favourites <a href="https://brickelo.com/ranking" class="text-green-400 font-bold">here</a>.</p>
      ]
    },
    {
      id: 1,
      title: 'Which LEGO Theme Has The Best Minifigures?',
      slug: 'which-lego-theme-has-the-best-minifigures',
      previewImg: 'which-lego-theme-has-the-best-minifigures.png',
      articleImg: 'which-lego-theme-has-the-best-minifigures.png',
      alt: 'LEGO Star Wars, Lord of the Rings, Marvel, and CMF theme logos',
      summary: 'In this article, I take a look at the average rating of minifigures across all LEGO themes to see which has the best minifigures.',
      date: '28th March 2024',
      updated: '28th March 2024',
      author: 'Graham',
      content: [
            <p class="mb-2">With over 215,000 user ratings now submitted, I thought it was time to run some analysis on which theme has the best minifigures overall.</p>,
            <p class="mb-2">If you take a look at the <a href="https://brickelo.com/results" class="text-green-400 font-bold">top 100 minifigures</a>, you'll see that it's largely dominated by licensed themes, such as Lord of the Rings, Pirates of the Caribbean, Star Wars, Marvel, and DC. But do these minifigures offer up the best minifigures overall? Let's take a look...</p>,
            <p class="mb-2">Here are the top 5 themes by average minifigure rating:</p>,
            <div class="flex justify-center items-center">
              <table class="w-full mb-6">
                      <thead class="border-b border-white border-opacity-50">
                          <tr class="text-green-400 font-bold">
                              <th>Theme</th>
                              <th className="text-center">Avg. Minifigure Rating</th>
                              <th className="text-center">No. of Minifigures</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr class="border-b border-white border-opacity-50">
                            <td class="py-1 text-left">The LEGO Batman Movie</td>
                            <td class="py-1 text-center">1079.76</td>
                            <td class="py-1 text-center">44</td>
                          </tr>
                          <tr class="border-b border-white border-opacity-50">
                            <td class="py-1 text-left">Pirates of the Caribbean</td>
                            <td class="py-1 text-center">1070.16</td>
                            <td class="py-1 text-center">41</td>
                          </tr>
                          <tr class="border-b border-white border-opacity-50">
                            <td class="py-1 text-left">The Lord of the Rings</td>
                            <td class="py-1 text-center">1069.73</td>
                            <td class="py-1 text-center">64</td>
                          </tr>
                          <tr class="border-b border-white border-opacity-50">
                            <td class="py-1 text-left">The LEGO Movie 2</td>
                            <td class="py-1 text-center">1065.74</td>
                            <td class="py-1 text-center">19</td>
                          </tr>
                          <tr class="border-b border-white border-opacity-50">
                            <td class="py-1 text-left">Monster Fighters</td>
                            <td class="py-1 text-center">1055.69</td>
                            <td class="py-1 text-center">21</td>
                          </tr>           
                      </tbody>
                  </table>
                </div>,
                <p class="mb-2">A little suprisingly, The LEGO Batman Movie claims the top spot for now. Although the theme doesn't have any minifigures in the top 100 (at the time of writing <a href="https://brickelo.com/results/the-lego-batman-movie/minifigures/coltlbm24" class="text-green-400 font-bold">Baturion</a> is the highest placed a #149), all but three minifigures from the theme have ratings higher than the starting rating of 1,000. The theme offers up many playful variants of Batman and some unique and interesting supervillian minifigures, all of which are highly detailed.</p>,
                <p class="mb-2">It's less of a surpise to see The Lord of the Rings and Pirates of the Caribbean up there, as these themes are well represented in the <a href="https://brickelo.com/results" class="text-green-400 font-bold">top 100</a>. You might expect to see Star Wars up there, but given the theme has well over 1,000 minifigures, many are bound to be subpar and therefore bring the average down.</p>,
                <p class="mb-2">Another metric would be to look at the number of minifigures each theme has in the top 100:</p>,
                <table class="w-full mb-6">
                    <thead class="border-b border-white border-opacity-50">
                        <tr class="text-green-400 font-bold">
                            <th className="text-left">Theme</th>
                            <th className="text-center">No. of Minifigures in Top 100</th>
                        </tr>
                    </thead>
                    <tbody class="border-b border-white border-opacity-50">
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">Star Wars</td>
                          <td class="py-1 text-center">38</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">Marvel</td>
                          <td class="py-1 text-center">18</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">DC</td>
                          <td class="py-1 text-center">12</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">The Lord of the Rings</td>
                          <td class="py-1 text-center">11</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">Collectible Minifigures</td>
                          <td class="py-1 text-center">11</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">Pirates of the Caribbean</td>
                          <td class="py-1 text-center">8</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">Ideas</td>
                          <td class="py-1 text-center">1</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">Disney</td>
                          <td class="py-1 text-center">1</td>
                        </tr>              
                    </tbody>
                </table>,

                <p class="mb-2">With this measurement, Star Wars is a clear winner as over a third of the minifigures in the top 100 come from the theme. However, we should remember that in terms of minifigure count, Star Wars is LEGO's biggest theme, so you would expect such a result. It's also the most popular and most collectible theme, and this bias will undoubtedly be having some effect. Once I have more user ratings, I'll provide a more in-depth analysis that shows the percentage of theme minifigures in the top 100, top 500, and top 1,000 etc.</p>,

                <p class="mb-2">Finally, here are the worst performing themes:</p>,
                <div class="flex justify-center items-center">
                  <table class="w-full mb-6">
                      <thead class="border-b border-white border-opacity-50">
                          <tr class="text-green-400 font-bold">
                              <th>Theme</th>
                              <th className="text-center">Avg. Minifigure Rating</th>
                              <th className="text-center">No. of Minifigures</th>
                          </tr>
                      </thead>
                      <tbody class="border-b border-white border-opacity-50"> 
                          <tr class="border-b border-white border-opacity-50">
                            <td class="py-1 text-left">Jurassic World</td>
                            <td class="py-1 text-center">935.83</td>
                            <td class="py-1 text-center">123</td>
                          </tr>
                          <tr class="border-b border-white border-opacity-50">
                            <td class="py-1 text-left">Harry Potter</td>
                            <td class="py-1 text-center">939.82</td>
                            <td class="py-1 text-center">507</td>
                          </tr>
                          <tr class="border-b border-white border-opacity-50">
                            <td class="py-1 text-left">Avatar</td>
                            <td class="py-1 text-center">952.58</td>
                            <td class="py-1 text-center">27</td>
                          </tr>
                          <tr class="border-b border-white border-opacity-50">
                            <td class="py-1 text-left">Agents</td>
                            <td class="py-1 text-center">954.93</td>
                            <td class="py-1 text-center">33</td>
                          </tr>
                          <tr class="border-b border-white border-opacity-50">
                            <td class="py-1 text-left">Alpha Team</td>
                            <td class="py-1 text-center">957.82</td>
                            <td class="py-1 text-center">30</td>
                          </tr>     
                      </tbody>
                  </table>
                </div>,

                <p class="mb-2">Jurassic World comes bottom of the pile and let's face it, no one collects Jurassic World sets for the minifigures. The big surprise here is Harry Potter, given that it's another highly popular theme. However, many of the minifigures are very plain, and how many versions of Harry, Ron, and Hermione have we got? Avatar is also low down, with its long-legged minifigures dividing opinion. I for one quite like them, but I'm in the minority.</p>,
                <p class="mb-2">Thanks for reading, and remember you can vote on your favourite minifigures <a href="https://brickelo.com/ranking" class="text-green-400 font-bold">here!</a></p>,
      ],
    },
  ];

BlogData.forEach(item => {
  item.updated = item.updated.replace(/(\d+)(st|nd|rd|th)/, '$1'); // Remove suffix
});

BlogData.sort((a, b) => new Date(b.updated) - new Date(a.updated));
  
  export default BlogData;